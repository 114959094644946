/* Slideshow Container */
.slideshow {
  position: relative;
  width: 100%;
  height: 100%; /* Ensure slideshow takes full height */
  margin: auto;
  overflow: hidden;
  max-height: 600px;
  background-color: #f3f3f3; /* Background color to show before images load */
}

/* Slides Container */
.slides-container {
  display: flex;
  transition: transform 1s ease; /* Smooth horizontal transition */
  width: 100%; /* Ensures container stretches horizontally */
}

/* Individual Slide */
.slide {
  flex: 0 0 100%; /* Each slide takes full width */
  height: 100%; /* Ensure slide takes full height */
}

/* Slide Image */
.slide img {
  width: 100%;
  height: 100%; /* Ensure image takes full height */
  object-fit: cover; /* Ensure image covers the container */
}

/* Navigation Buttons */
button.prev,
button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00bfff; /* Light blue background */
  color: white; /* White arrow */
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2; /* Ensure buttons are above slides */
  width: 50px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
}

button.prev {
  left: 10px;
}

button.next {
  right: 10px;
}

button.prev:hover,
button.next:hover {
  background-color: #009acd; /* Darker blue on hover */
}

/* Pop-Out Image */
.pop-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Cover the full width of the viewport */
  height: 100vh; /* Cover the full height of the viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100; /* High z-index to ensure it's on top */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.pop-out img {
  max-height: 80vh; /* Limits the image height to 80% of the viewport height */
  max-width: 80vw; /* Limits the image width to 80% of the viewport width */
  border-radius: 8px; /* Optional styling */
}

/* Responsive Design */
@media (max-width: 768px) {
  .slideshow {
    max-width: 100%;
    aspect-ratio: 4 / 3; /* Adjust aspect ratio for smaller screens */
  }

  button.prev,
  button.next {
    padding: 8px;
    width: 40px; /* Adjust width for smaller screens */
    height: 40px; /* Adjust height for smaller screens */
  }
}
