.footer {
  background-color: #ff8800;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  color: white;
  position: relative;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.contact-info {
  text-align: right;
}

.contact-info p {
  margin: 5px 0;
}

.social-media {
  margin-top: 10px;
}

.social-media a {
  color: white;
  margin-right: 10px;
  text-decoration: none;
  font-size: 18px;
}

.social-media a:hover {
  text-decoration: underline;
}
