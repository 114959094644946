/* src/styles/RequestsManagement.css */

.requests-management {
  padding: 20px;
}

.requests-management h3 {
  margin-bottom: 20px;
}

.requests-table {
  width: 100%;
  border-collapse: collapse;
}

.requests-table th,
.requests-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.requests-table th {
  background-color: #cce5ff; /* Light blue for the header */
}

.requests-table tr {
  cursor: pointer;
}

.requests-table tr.status-accepted {
  background-color: #cce5ff; /* Light blue for accepted */
}

.requests-table tr.status-declined {
  background-color: #f8d7da; /* Light red for declined */
}

.requests-table tr.status-completed {
  background-color: #d4edda; /* Light green for completed */
}

.requests-table .hide-on-mobile {
  display: table-cell;
}

@media (max-width: 768px) {
  .requests-table .hide-on-mobile {
    display: none;
  }
}

/* Legend styles */
.legend {
  margin-top: 20px;
}

.legend p {
  display: inline-block;
  margin-right: 20px;
}

.legend span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend .status-accepted {
  background-color: #cce5ff;
}

.legend .status-completed {
  background-color: #d4edda;
}

.legend .status-declined {
  background-color: #f8d7da;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the modal is on top */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  overflow-y: auto;
  z-index: 10000; /* Ensure modal content is on top of the modal backdrop */
}

.modal-content button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #0056b3;
}

.modal-content button:last-of-type {
  background-color: #6c757d;
}

.modal-content button:last-of-type:hover {
  background-color: #5a6268;
}
