/* src/styles/ServiceRequestForm.css */

.service-request-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-request-form h2 {
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.5em; /* Increased font size for larger screens */
}

.service-request-form form {
  display: flex;
  flex-direction: column;
}

.service-request-form label {
  margin-bottom: 1em;
}

.service-request-form input,
.service-request-form textarea,
.service-request-form select {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.service-request-form textarea::placeholder {
  color: #999;
}

.service-request-form .additional-details-textarea {
  height: 80px; /* Adjust height to make it smaller */
}

.service-request-form button {
  padding: 0.75em;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  font-size: 1em;
  cursor: pointer;
  margin-top: 1em;
}

.service-request-form button:hover {
  background: #0056b3;
}

.divider {
  height: 2px;
  background-color: #ccc;
  margin: 1em 0;
}

.dropdown {
  position: relative;
}

.dropdown button {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  color: #000;
  cursor: pointer;
  text-align: left;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu label {
  display: block;
  padding: 0.5em;
  cursor: pointer;
}

.dropdown-menu label:hover {
  background: #f0f0f0;
}

.dropdown-menu input {
  margin-right: 0.5em;
}

/* Responsive design */
@media (max-width: 768px) {
  .service-request-form {
    padding: 1em;
  }

  .service-request-form h2 {
    font-size: 1.5em;
  }

  .service-request-form button {
    font-size: 1em;
  }
}

/* Larger screens */
@media (min-width: 1024px) {
  .service-request-form h2 {
    font-size: 2em;
  }

  .service-request-form label {
    font-size: 1.2em;
  }

  .service-request-form input,
  .service-request-form textarea,
  .service-request-form select {
    font-size: 1.2em;
    padding: 0.75em;
  }

  .service-request-form button {
    font-size: 1.2em;
  }
}
