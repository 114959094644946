.header {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  transition: height 0.3s ease;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  margin-right: 10px;
}

.company-title {
  font-size: 24px;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links ul {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 18px;
  display: block; /* Ensure links are block-level for proper hover effect */
}

.nav-links a:hover {
  color: rgb(255, 123, 0);
}

.nav-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: rgb(255, 123, 0);
  transition: width 0.3s;
}

.nav-links a:hover::after {
  width: 100%;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon img {
  height: 30px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header.menu-open {
    flex-direction: column;
    align-items: center; /* Center the elements when menu is open */
  }

  .logo {
    flex-direction: row;
    align-items: center;
  }

  .company-title {
    font-size: 20px;
    margin-top: 0;
  }

  .nav-links {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links ul {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .nav-links li {
    width: auto;
  }

  .nav-links a {
    font-size: 16px;
    padding: 10px 20px;
    width: auto;
    text-align: center;
  }

  .menu-icon {
    display: block;
    align-self: flex-end;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
  }

  .logo img {
    height: 40px;
  }

  .company-title {
    font-size: 18px;
  }

  .nav-links a {
    font-size: 14px;
  }
}
