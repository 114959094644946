.landing-page {
    width: 100%;
    box-sizing: border-box;
  }
  
  .hero {
    width: 100%;
    overflow: hidden;
  }
  
  .services-overview, .about-us {
    padding: 20px;
    text-align: center;
  }
  
  .services-overview h2, .about-us h2 {
    color: #003e9a;
    margin-bottom: 10px;
  }
  
  .services-overview p, .about-us p {
    color: #333;
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .services-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  