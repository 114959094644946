.service-card {
    position: relative;
    width: 300px;
    aspect-ratio: 2 / 3;
    background-color: white;
    color: #333;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-card-icon {
    height: 100px;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
  }
  
  .service-card h3 {
    margin: 10px 0;
    font-size: 20px;
    color: #003e9a;
    text-align: center;
  }
  
  .service-card-description {
    text-align: center;
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    .service-card {
      width: 100%;
      aspect-ratio: auto;
    }
  
    .service-card-icon {
      height: 80px;
      width: 80px;
    }
  
    .service-card h3 {
      font-size: 18px;
    }
  
    .service-card-description {
      font-size: 14px;
    }
  }
  