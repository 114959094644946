.about-us-container {
  text-align: center; 
  padding: 20px;
  font-family: Arial, sans-serif;
}

.about-image {
  max-width: 100%; 
  height: auto;
  margin-bottom: 20px; 
}

.about-section, .mission-statement {
  margin-bottom: 20px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
}

h1 {
  color: #333;
  transition: color 0.3s;
}

.about-section p {
  text-align: left;
  color: #666;
  margin-bottom: 10px;
}

.mission-statement p {
  text-align: left;
  color: #666;
  margin-bottom: 10px;
}
  
  /* Change heading color when paragraphs are hovered */
  .about-section p:hover + h1,
  .mission-statement p:hover + h1 {
    color: lightblue;
  }
  