/* src/styles/LoginPage.css */

.login-page {
    max-width: 400px;
    margin: 0 auto;
    padding: 1em;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-page h2 {
    text-align: center;
    margin-bottom: 1em;
  }
  
  .form-group {
    margin-bottom: 1em;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5em;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 1em;
  }
  
  button {
    width: 100%;
    padding: 0.75em;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: white;
    font-size: 1em;
    cursor: pointer;
  }
  
  button:hover {
    background: #0056b3;
  }
  