/* src/styles/SlideShowManagement.css */

.slide-show-management {
    padding: 20px;
  }
  
  .slide-show-management h3 {
    margin-bottom: 20px;
  }
  
  .slide-show-management form {
    margin-bottom: 20px;
  }
  
  .slide-show-management label {
    display: block;
    margin-bottom: 10px;
  }
  
  .slide-show-management input[type="text"],
  .slide-show-management textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  .slide-show-management button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .slides-list {
    margin-top: 20px;
  }
  
  .slides-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .slides-list th,
  .slides-list td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .slides-list th {
    background-color: #f2f2f2;
  }
  
  .slides-list img {
    display: block;
    max-width: 50px;
    height: auto;
  }
  
  .slides-list button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
  }
  
  .slides-list button img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .slides-list button:hover img {
    filter: brightness(0.8);
  }
  
  .slides-list .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Adjusted for smaller screens */
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Adjusted for better mobile view */
    max-width: 400px; /* Limit the maximum width on larger screens */
    text-align: center;
    overflow-y: auto; /* Ensures the content is scrollable if it overflows */
  }
  
  .modal-content img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .modal-content h4 {
    margin-top: 0;
  }
  
  .modal-content textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    resize: none; /* Prevent resizing */
    max-height: 150px; /* Limit the height of the textarea */
  }
  
  .modal-content button {
    margin: 5px;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .slide-show-management {
      padding: 10px;
    }
  
    .slide-show-management h3 {
      font-size: 24px;
    }
  
    .slide-show-management label {
      font-size: 16px;
    }
  
    .slide-show-management input[type="text"],
    .slide-show-management textarea {
      padding: 12px;
      font-size: 16px;
    }
  
    .slide-show-management button[type="submit"] {
      padding: 15px 30px;
      font-size: 16px;
    }
  
    .slides-list th,
    .slides-list td {
      padding: 8px;
      font-size: 14px; /* Adjusted font size for better fit */
    }
  
    .slides-list img {
      max-width: 40px;
    }
  
    .slides-list button img {
      width: 24px;
      height: 24px;
    }
  
    .slides-list .description {
      max-width: 100px; /* Adjusted for smaller screens */
    }
  
    .modal-content {
      width: 90%;
      max-width: 90%;
      padding: 15px;
    }
  
    .modal-content textarea {
      max-height: 200px;
      font-size: 16px;
    }
  
    .modal-content button {
      padding: 10px 20px;
      font-size: 16px;
    }
  }
  
  /* Further adjustments for very small screens */
  @media (max-width: 480px) {
    .slide-show-management h3 {
      font-size: 20px;
    }
  
    .slide-show-management label {
      font-size: 14px;
    }
  
    .slide-show-management input[type="text"],
    .slide-show-management textarea {
      padding: 10px;
      font-size: 14px;
    }
  
    .slide-show-management button[type="submit"] {
      padding: 10px 20px;
      font-size: 14px;
    }
  
    .slides-list th,
    .slides-list td {
      padding: 6px;
      font-size: 12px; /* Further adjust font size */
    }
  
    .slides-list img {
      max-width: 30px;
    }
  
    .slides-list button img {
      width: 20px;
      height: 20px;
    }
  
    .slides-list .description {
      max-width: 80px; /* Adjusted for very small screens */
    }
  
    .modal-content textarea {
      max-height: 150px;
      font-size: 14px;
    }
  
    .modal-content button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  