/* src/styles/ControlPanel.css */

.control-panel {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1em;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .control-panel h2 {
    text-align: center;
    margin-bottom: 1em;
  }
  
  .control-panel-content {
    display: flex;
  }
  
  .side-menu {
    flex: 1;
    background: #f1f1f1;
    border-radius: 10px;
    margin-right: 1em;
  }
  
  .side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .side-menu li {
    padding: 1em;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .side-menu li:hover,
  .side-menu li.active {
    background: #007bff;
    color: white;
  }
  
  .main-content {
    flex: 3;
    padding: 1em;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .control-panel-content {
      flex-direction: column;
    }
  
    .side-menu {
      margin-bottom: 1em;
    }
  }
  